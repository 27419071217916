import {
  faRotateLeft,
  faClockRotateLeft,
  faCloudRain,
  faProjectDiagram,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import {
  MenuItem,
  PageRoute,
} from "src/entities/common.entities";

export const menuItems: Record<string, MenuItem> = {
  claim: {
    text: "Claim Rewards",
    to: PageRoute.claimCardano,
    activeRoute: [PageRoute.claimCardano, PageRoute.depositCardano],
    icon: faCloudRain,
  },
  history: {
    text: "Claim History",
    to: PageRoute.historyCardano,
    activeRoute: [PageRoute.historyCardano],
    icon: faClockRotateLeft,
  },
  projects: {
    text: "Browse Projects",
    to: PageRoute.projectsCardano,
    activeRoute: [PageRoute.projectsCardano],
    icon: faProjectDiagram,
  },
  // pools: {
  //   text: "Stake Pools",
  //   to: PageRoute.poolsCardano,
  //   activeRoute: [PageRoute.poolsCardano],
  //   icon: faCubesStacked,
  // },
};

export default function MenuCardano() {
  const location = useLocation().pathname;

  const LinkButton = ({ menuItem }: { menuItem: MenuItem }) => {
    return (
      <Link
        to={menuItem.to}
        className={`${
          menuItem.activeRoute.includes(location as PageRoute)
            ? "text"
            : "text-inactive"
        } flex flex-row items-center gap-2`}
      >
        <FontAwesomeIcon className="w-4" icon={menuItem.icon} />
        {menuItem.text}
      </Link>
    );
  };

  return (
    <div className="hidden sm:flex flex-col gap-8">
      <p className="text-3xl opacity-0">_</p>
      <div
        className={"background text-lg p-5 rounded-2xl w-56 h-fit"}
      >
        <div className="menu">
          <div className="menu-content">
            <div className="flex flex-col gap-2">
              {Object.values(menuItems).map((menuItem: MenuItem) => (
                <LinkButton key={menuItem.text} menuItem={menuItem} />
              ))}
              <div>
                <a
                  target="_self"
                  rel="noreferrer"
                  href="https://silktoad.io/"
                  className="flex flex-row items-center gap-2 text-inactive"
                >
                  <FontAwesomeIcon className="w-4" icon={faRotateLeft} />
                  Return Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
