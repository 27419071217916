import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import Agnostic from "./agnostic";
import Cardano from "./cardano";

if (process.env.NODE_ENV === "production") ReactGA.initialize("G-DYEX6NLZJ3");

interface Props {
  children: JSX.Element;
}

export default function BlockchainWrapper({ children }: Props) {
  const location = useLocation().pathname;
  const isOnCardano = location.includes("cardano");
  if (process.env.NODE_ENV === "production") {
    ReactGA.set({ page: location });
    ReactGA.send(location);
  }

  if (isOnCardano) {
    return <Cardano>{children}</Cardano>;
  } else {
    return <Agnostic>{children}</Agnostic>;
  }
}
